<template>
<div id="app_mobil" style="backgroundImage: url('/static/b1.png'); background-repeat: no-repeat; background-size:cover; height:180%">
  <v-container id="contenedor" fluid tag="section" style=" top:10%; ">
    <v-row style="display:flex; justify-content:center;">
      <v-col xs="12" lg="8">

        <base-material-card color="primary" icon="library_books" title="Facturación" class="elevation-1 px-5 py-3">
          <v-container>
            <v-card-text>
              <v-form ref="busquedaform">
                <div class="busqueda" v-if="encontrado==false">
                  <div class="text-center" style="width:100%; ">
                    <img src="/static/logo-negro.png" alt="LaBodeguita" style="width:50%" />

                  </div>
                  <v-row>
                    <v-col sm="12" cols="12">
                      <v-text-field v-model="model.numero_ticket" label="No. de Ticket" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="12" cols="12">
                      <v-text-field v-model="model.digito" label="Código de Seguridad" :rules="[rules.required, validaLen]" maxlength="3" ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row style="display:flex; justify-content:center">
                    <v-col sm="6">
                      <v-btn block class="ma-2" outlined color="indigo" @click.native="buscar_ventana()"> Buscar </v-btn>
                    </v-col>
                  </v-row>
                </div>
              </v-form>
              <div v-if="encontrado">
                <div class="text-center" style="width:100%; ">
                  <img src="/static/logo-negro.png" alt="LaBodeguita" style="margin-top: -50px; width:50%;" />

                </div>
                <v-form ref="form" lazy-validation>

                  <v-row>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-text-field v-model="json.Receptor.Rfc" label="RFC" :rules="[rules.required]" @blur="buscar_cliente()" @input="json.Receptor.Rfc = json.Receptor.Rfc.toString().trim().toUpperCase()"></v-text-field>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-text-field v-model="json.Receptor.Nombre" @input="json.Receptor.Nombre = json.Receptor.Nombre.toString().trim()" label="Nombre" :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-autocomplete v-model="json.Receptor.RegimenFiscalReceptor" :items="regimenesFiscales" :hide-no-data="true" item-text="text" item-value="id" label="Seleccione Régimen Fiscal Receptor" :rules="[rules.required]">
                      </v-autocomplete>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-text-field v-model="json.Receptor.DomicilioFiscalReceptor" label="Código Postal Receptor" :rules="[rules.required]"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col sm="12" md="8" lg="8" cols="12">
                      <v-text-field label="Domicilio" v-model="json.Receptor.Domicilio.Calle"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2" cols="12">
                      <v-text-field label="Ext." v-model="json.Receptor.Domicilio.NumeroExterior"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="2" lg="2" cols="12">
                      <v-text-field label="Int." v-model="json.Receptor.Domicilio.NumeroInterior"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col sm="12" md="6" lg="6" cols="12">
                      <v-text-field label="Colonia" v-model="json.Receptor.Domicilio.Colonia"></v-text-field>
                    </v-col>
                    <v-col sm="12" md="6" lg="6" cols="12">
                      <v-text-field label="Ciudad" v-model="json.Receptor.Domicilio.Estado"></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-autocomplete v-model="json.Receptor.UsoCFDI" :items="usosCFDI" :hide-no-data="true" item-text="text" item-value="id" label="Seleccione Uso de CFDI" :rules="[rules.required]">
                      </v-autocomplete>
                    </v-col>
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-autocomplete v-model="json.FormaPago" :items="formasPago" :hide-no-data="true" item-text="text" item-value="id" label="Seleccione Forma de Pago" :rules="[rules.required]">
                      </v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col lg="6" md="6" sm="6" cols="6">
                      <v-text-field v-model="model_cfdi.email" label="Email" :rules="[rules.required, rules.email]"></v-text-field>
                    </v-col>                  
                    <v-col lg="6" md="6" sm="6" cols="6">
                      <v-text-field readonly :value="total_nota" label="Total de la Nota"></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <span><strong>**Nota:</strong> Revisar que todos los datos esten correctos, ya que se utiliza la Version 4 de CFDI y debe coincidir el Nombre, Régimen y Domicilio con los registrados en el SAT</span>
                  </v-row>
                  <br>
                  <v-row style="display:flex; justify-content:center">
                    <v-col lg="6" md="6" sm="12" cols="12">
                      <v-btn block class="ma-2" outlined :loading="loading" color="indigo" @click.native="facturar"> Facturar </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </div>
            </v-card-text>
          </v-container>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="500px">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">add</v-icon>&nbsp;
          <span class="subheading">
            <strong>¡Operación Exitosa!</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <h6>La Factura se generó correctamente y será enviada a su correo!</h6>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" @click.native="get_file(2)">
          <v-icon>picture_as_pdf</v-icon> Descargar PDF
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.native="get_file(1)">
          <v-icon>code</v-icon> Descargar XML
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>



<script>
import moment from "moment";
// import pdf from 'vue-pdf'

export default {
  name: "appMobil",
  components: {
    // pdf
  },

  created: function() {
    var self = this;
    this.id_venta = "";
    this.get_usos_cfdi();
    this.get_regimenes_fiscales();
    this.model = this.clean_model();
    this.json = this.clean_model_json();
    this.model.numero_ticket = this.$route.query.q;
    this.get_formas_pago().then(function(r){
      if (self.model.numero_ticket != '' && self.model.numero_ticket != undefined) {
        self.buscar();
      }
    });
  },
  data: () => ({
    loading:false,
    total_nota:"",
    model_cliente: {},
    item: {},
    rules: {
      required: v => !!v || "Este campo es requerido",
      decimal: value => {
        const pattern = /^[\d]*(\.{0,1}[\d]*)$/;
        return pattern.test(value) || "Número decimal no válido";
      },
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Correo electrónico no valido";
      },
    },
    factura_global: null,
    encontrado: false,
    modal: false,
    model: {},
    model_ticket: {},
    model_sucursal: {},
    model_cfdi: {
      email: "",
      rfc: "",
      json: ""
    },
    search: undefined,
    encontrado: false,
    api: 'https://ozu2hub36m.execute-api.us-east-1.amazonaws.com/dev/',
    json: {},
    usosCFDI: [],
    formasPago: [],
    formasPagoAll: [],
    regimenesFiscales: [],
    id_solicitud: null,
    id_venta: '',
    db_config: {
      headers: {
        Authorization: "Basic YWRtaW46YWRtaW4xMjMq"
      }
    }
  }),
  methods: {
    formatNumberDec: function(numero, decimales){
        if (numero == undefined || numero == null || numero == "")
            return "0";
        numero = Number(numero);
        if (isNaN(numero)){
            return "0";
        } else
            return (numero).toFixed(decimales).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    },
    buscar_cliente: function() {
      window.dialogLoader.show('Espere un momento por favor..');
      let data = {
        "selector": {
          "type": "clientes",
          "nombre": {"$exists":true},
          "rfc": this.json.Receptor.Rfc,          
          "estatus": "Activo",
        },
        "use_index":"_design/fe0c8e6363f112920ddc99aa8d1b519fcbb2c570"
      };

      window.axios.create({
          withCredentials: false
        })
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
        .then(response => {
          if (response.data.docs.length > 0) {
            var cliente = response.data.docs[0];
            var datos_fiscales = cliente.datos_fiscales;
            this.model_cliente = cliente;
            try {
              this.json.Receptor.Nombre = cliente.datos_fiscales.razon_social;
              this.json.Receptor.DomicilioFiscalReceptor = cliente.datos_fiscales.codigo_postal;
              this.json.Receptor.RegimenFiscalReceptor = cliente.datos_fiscales.regimen_fiscal;
              this.json.Receptor.Domicilio = {
                "Calle": datos_fiscales.domicilio != undefined && datos_fiscales.domicilio != null ? datos_fiscales.domicilio : "",
                "CodigoPostal": datos_fiscales.codigo_postal != undefined && datos_fiscales.codigo_postal != null ? datos_fiscales.codigo_postal : "",
                "Colonia": datos_fiscales.colonia != undefined && datos_fiscales.colonia != null ? datos_fiscales.colonia : "",
                "Estado": datos_fiscales.ciudad != undefined && datos_fiscales.ciudad != null ? datos_fiscales.ciudad : "",
                "Localidad": "",
                "Municipio": datos_fiscales.ciudad != undefined && datos_fiscales.ciudad != null ? datos_fiscales.ciudad : "",
                "NumeroExterior": datos_fiscales.numero_exterior != undefined && datos_fiscales.numero_exterior != null ? datos_fiscales.numero_exterior : "",
                "NumeroInterior": datos_fiscales.numero_interior != undefined && datos_fiscales.numero_interior != null ? datos_fiscales.numero_interior : "",
                "Pais": "MEX",
                "Referencia": ""
              };
            } catch (error) {
              //No tiene datos fiscales
            }
            this.model_cfdi.email = cliente.email;
          } else {
            this.model_cliente = {};
          }
        })
        .catch(error => {
          console.log(error);
        }).then(() => {
          window.dialogLoader.hide();
        });
    },
    get_sucursal: async function(id) {
      window.dialogLoader.show('Espere un momento por favor..');
      let data = {
        "selector": {
          "type": "sucursales",
          "_id": id,
        },
      };

      await window.axios.create({
          withCredentials: false
        })
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
        .then(async response => {
          if (response.data.docs.length > 0) {
            var sucursal = response.data.docs[0];
            this.model_sucursal = sucursal;
          } else {
            this.model_sucursal = {};
          }
        })
        .catch(error => {
          console.log(error);
        }).then(() => {
          window.dialogLoader.hide();
        });
    },
    validaLen: function(val) {
      if (val != undefined && val != null && val != "") {
        if (val.toString().length != 3)
          return "Deben ser 3 caracteres";
      }
      return true;
    },
    clean_model: function() {
      return {
        numero_ticket: ""
      };
    },
    clean_model_sucursal: function() {
      return {};
    },
    clean_model_json: function() {
      return {
        "Sello": "",
        "Serie": "",
        "Folio": "",
        "SubTotal": "",
        "TipoDeComprobante": "I",
        "Total": "",
        "Version": "4.0",
        "Fecha": "",
        "LugarExpedicion": "",
        "Moneda": "",
        "NoCertificado": "",
        "MetodoPago": "",
        "FormaPago": "",
        "Exportacion": "",
        "Emisor": {
          "Nombre": "",
          "RegimenFiscal": "",
          "Rfc": "",
          "Domicilio": {
            "Calle": "",
            "CodigoPostal": "",
            "Colonia": "",
            "Estado": "",
            "Localidad": "",
            "Municipio": "",
            "NumeroExterior": "",
            "Pais": "",
            "Referencia": ""
          }
        },
        "Receptor": {
          "Nombre": "",
          "Rfc": "",
          "UsoCFDI": "",
          "RegimenFiscalReceptor": "",
          "DomicilioFiscalReceptor": "",
          "Domicilio": {
            "Calle": "",
            "CodigoPostal": "",
            "Colonia": "",
            "Estado": "",
            "Localidad": "",
            "Municipio": "",
            "NumeroExterior": "",
            "Pais": "",
            "Referencia": ""
          }
        },
        "Conceptos": null,
        "Impuestos": null,
      }
    },
    get_usos_cfdi: function() {
      let data = {
        "selector": {
          "type": "sat_usos_cfdi",
          "estatus": "Activo"
        },
      };

      window.axios.create({
          withCredentials: false
        })
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.forEach((uso) => {
              this.usosCFDI.push({
                "id": uso.clave,
                "text": uso.clave + "-" + uso.nombre,
              });
            });
          } else
            this.usosCFDI = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los catalogos del SAT.",
            footer: ""
          });
        });
    },
    get_formas_pago: function() {
      var self = this;
      return new Promise(async function (resolve, reject) {
        let data = {
          "selector": {
            "type": "sat_formas_pago",
            "estatus": "Activo"
          },
        };

        window.axios.create({
            withCredentials: true
          })
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, self.db_config)
          .then(response => {
            if (response.data.docs.length > 0) {
              response.data.docs.forEach((uso) => {
                self.formasPagoAll.push({
                  "id": uso.clave,
                  "text": uso.clave + "-" + uso.nombre,
                });
              });
            } else
              self.formasPagoAll = [];
            return resolve(true);
          })
          .catch(error => {
            self.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los catalogos del SAT.",
              footer: ""
            });
            return reject(false);
          });
      });
    },
    get_regimenes_fiscales: function() {
      let data = {
        "selector": {
          "type": "sat_regimenes_fiscales",
          "estatus": "Activo"
        },
      };

      window.axios.create({
          withCredentials: true
        })
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
        .then(response => {
          if (response.data.docs.length > 0) {
            response.data.docs.forEach((uso) => {
              this.regimenesFiscales.push({
                "id": uso.clave,
                "text": uso.clave + "-" + uso.nombre,
              });
            });
          } else
            this.regimenesFiscales = [];
        })
        .catch(error => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los catalogos del SAT.",
            footer: ""
          });
        });
    },
    buscar: function() {
      if (this.model.numero_ticket != "" && this.model.numero_ticket != undefined) {
        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
          "selector": {            
            "_id": this.model.numero_ticket,            
          }
        };
        var self = this;

        window.axios.create({
            withCredentials: false
          })
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + this.model.numero_ticket, this.db_config)
          .then(response => {
            
              this.id_venta = this.model.numero_ticket;
              var tmp = response.data;
              if ( ["Finalizada", "Factura Global"].includes(tmp["estatus"])){
                var mes_actual = window.moment().month();
                var fecha_venta = tmp.fecha;
                var mes_venta = window.moment(fecha_venta).month();
                if (mes_actual != mes_venta) {
                  this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "La venta se encuentra fuera de periodo de facturación",
                    footer: ""
                  });
                } else {
                  this.model_ticket = response.data;

                  this.total_nota = "$"+this.formatNumberDec(this.model_ticket.total.toFixed(2),2);
                  //Para que aparezcan solo las formas de pago de la venta
                  var formas_pago_venta = [];
                  if(this.model_ticket.pago != undefined){
                    for(var k in this.model_ticket.pago){
                      formas_pago_venta.push(this.model_ticket.pago[k].clave)
                    }
                  }
                  if(formas_pago_venta.length>0){
                    this.formasPago = this.formasPagoAll.filter(e => formas_pago_venta.includes(e.id));
                  } else {
                    this.formasPago = JSON.parse(JSON.stringify(this.formasPagoAll));
                  }

                  this.encontrado = true;
                }
              } else {
                this.encontrado = false;
                if(tmp["estatus"] == "Facturada"){
                  this.key_xml = "BGU960207S5A/" + tmp.id_facturacion + ".xml";
                  this.key_pdf = "BGU960207S5A/" + tmp.id_facturacion + ".pdf";
                  this.modal = true;

                } else {
                   this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "No se encontró el registro ó fue cancelado. Favor de Verificar.",
                    footer: ""
                  });
                }
               
              }              
            
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "No se encontró el registro. Intente nuevamente por favor.",
              footer: ""
            });
          }).then(async function() {
            if (self.encontrado) {
              await self.preparar_json();
            }
            window.dialogLoader.hide();
          });
      } else {
        this.$swal({
          type: "error",
          title: "Error",
          text: "Debes ingresar tu número de ticket",
          footer: "",
        });
      }
    },
    buscar_ventana: function() {
      if (this.$refs.busquedaform.validate() && this.model.numero_ticket != "" && this.model.numero_ticket != undefined && this.model.digito != "" && this.model.digito != undefined) {
        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
          "selector": {
            "ticket": this.model.numero_ticket.toString().toUpperCase().trim(),            
          },
          
          "use_index":"_design/1e7fd82e52f4e17082b520be8b8d4fc49ee8ec41",
        };
        var self = this;

        window.axios.create({
            withCredentials: false
          })
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data, this.db_config)
          .then(response => {
            if (response.data.docs.length > 0) {              
              var tmp = response.data.docs[0];
              if (tmp._id.endsWith(this.model.digito) && ["Finalizada", "Factura Global"].includes(tmp.estatus)) {//Verificamos que el codigo de seguridad coincida     
                
                  var mes_actual = window.moment().month();
                  var fecha_venta = tmp.fecha;
                  var mes_venta = window.moment(fecha_venta).month();
                  if (mes_actual != mes_venta) {
                      this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "La venta se encuentra fuera de periodo de facturación",
                        footer: ""
                      });
                  } else {
                      this.model_ticket = response.data.docs[0];

                      this.total_nota = "$"+this.formatNumberDec(this.model_ticket.total.toFixed(2),2);
                      //Para que aparezcan solo las formas de pago de la venta
                      var formas_pago_venta = [];
                      if(this.model_ticket.pago != undefined){
                        for(var k in this.model_ticket.pago){
                          formas_pago_venta.push(this.model_ticket.pago[k].clave)
                        }
                      }
                      if(formas_pago_venta.length>0){
                        this.formasPago = this.formasPagoAll.filter(e => formas_pago_venta.includes(e.id));
                      } else {
                        this.formasPago = JSON.parse(JSON.stringify(this.formasPagoAll));
                      }

                      this.encontrado = true;
                      this.id_venta = this.model_ticket._id;
                  }
              } else if (tmp._id.endsWith(this.model.digito) && tmp.estatus == "Facturada"){
                this.id_venta = "";
                this.total_nota = "";
                this.encontrado = false;
                
                this.key_xml = "BGU960207S5A/" + tmp.id_facturacion + ".xml";
                this.key_pdf = "BGU960207S5A/" + tmp.id_facturacion + ".pdf";
                this.modal = true;

              } else {
                
                this.id_venta = "";
                this.total_nota = "";
                this.encontrado = false;
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: "No se encontró la nota o fue cancelada. Comuníquese con La Bodeguita.",
                  footer: ""
                });
              }
              
            } else {
              this.id_venta = "";
              this.total_nota = "";
              this.encontrado = false;
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: "No se encontró la nota o ya se encuentra facturada. Comuníquese con La Bodeguita.",
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener la nota. Intente Nuevamente.",
              footer: ""
            });
          }).then(async function() {
            if (self.encontrado) {
              await self.preparar_json();
            }
            window.dialogLoader.hide();
          });
      } else {
        this.$swal({
          type: "error",
          title: "Error",
          text: "Debee ingresar número de ticket y código de seguridad",
          footer: "",
        });
      }
    },
    preparar_json: async function() {
      this.json = this.clean_model_json();
      this.model_sucursal = this.clean_model_sucursal();

      var subtotal_calculado = 0;

      var conceptos = [];
      var impuestos = {
        "TotalImpuestosTrasladados": 0,
        "TotalImpuestosRetenidos": 0,
        "Traslados": []
      };
      var impuestos_raw = [];

      var conceptos_procesados = [];
      for (let item in this.model_ticket.articulos) {
          var ex = conceptos_procesados.filter(x => x.nombre == this.model_ticket.articulos[item].nombre && x.precio_sin_impuestos == this.model_ticket.articulos[item].precio_sin_impuestos)
          if (ex.length > 0) {
            ex[0].cantidad += parseFloat(this.model_ticket.articulos[item].cantidad)
            ex[0].subtotal += parseFloat(this.model_ticket.articulos[item].subtotal)
            this.model_ticket.articulos[item].impuestos.forEach((iii) => {
              var exi = ex[0].impuestos.filter(y => y.clave == iii.clave && y.tasa == iii.tasa);
              if (exi.length > 0) {
                exi[0].importe_impuesto += parseFloat(iii.importe_impuesto);
              } else {
                ex[0].impuestos.push(iii);
              }
            });

          } else {
            conceptos_procesados.push(this.model_ticket.articulos[item])
          }
      }

      for (let item in conceptos_procesados) {
        let importe = parseFloat(conceptos_procesados[item].precio_sin_impuestos.toFixed(6)) * parseFloat(conceptos_procesados[item].cantidad.toFixed(6));

        conceptos_procesados[item].cantidad = parseFloat(conceptos_procesados[item].cantidad.toFixed(6));
        conceptos_procesados[item].subtotal = parseFloat(importe.toFixed(6));
        conceptos_procesados[item].precio_sin_impuestos = parseFloat(conceptos_procesados[item].precio_sin_impuestos.toFixed(6));
        conceptos_procesados[item].impuestos.forEach((impu) => {
            impu.importe_impuesto = parseFloat(impu.importe_impuesto.toFixed(4));
        });

      }

      for (let item in conceptos_procesados) {
        subtotal_calculado += conceptos_procesados[item].subtotal;

        let concepto = {
          "Cantidad": conceptos_procesados[item].cantidad.toString(),
          "ClaveProdServ": conceptos_procesados[item].producto_servicio.clave,
          "ClaveUnidad": conceptos_procesados[item].unidad_medida.clave == 'KPK' ? 'XPK' : conceptos_procesados[item].unidad_medida.clave,
          "Descripcion": conceptos_procesados[item].nombre,
          "Importe": conceptos_procesados[item].subtotal.toString(),
          "ValorUnitario": conceptos_procesados[item].precio_sin_impuestos.toString(),
          "Impuestos": {
            "Traslados": []
          }
        };
        conceptos_procesados[item].impuestos.forEach((im) => {
          let importe_calculado = parseFloat(conceptos_procesados[item].subtotal) * parseFloat( (im.tasa / 100).toString().padEnd(8, "0"));

          let impuesto = {
            "TipoFactor": "Tasa",
            "Importe": parseFloat(importe_calculado.toFixed(6)),
            "Impuesto": im.clave,
            "TasaOCuota": (im.tasa / 100).toString().padEnd(8, "0"),
            "Base": parseFloat(conceptos_procesados[item].subtotal)
          };
          impuestos_raw.push(impuesto);
          impuesto.Importe = impuesto.Importe.toString();
          impuesto.Base = impuesto.Base.toString();
          concepto.Impuestos.Traslados.push(impuesto);
        });

        if (concepto.Impuestos.Traslados.length == 0) {
          concepto.Impuestos = null;
          concepto.ObjetoImp = "01";
        } else {
          concepto.ObjetoImp = "02";
        }
        conceptos.push(concepto);
      }

      impuestos_raw.forEach((imp) => {
        var existe = impuestos.Traslados.filter(a => a.TasaOCuota == imp.TasaOCuota && a.Impuesto == imp.Impuesto);
        if (existe.length > 0) {
          existe = existe[0];
          existe.Importe += parseFloat(imp.Importe);
          existe.Base += parseFloat(imp.Base);
        } else {
          impuestos.Traslados.push({
            "TipoFactor": "Tasa",
            "Importe": parseFloat(imp.Importe),
            "Impuesto": imp.Impuesto.toString(),
            "TasaOCuota": imp.TasaOCuota,
            "Base": parseFloat(imp.Base)
          });
        }
      });

      impuestos.Traslados.forEach((tra) => {
        let tra_importe = Math.ceil(tra.Importe * 1000000) / 1000000;
        tra_importe = Math.round((tra_importe + Number.EPSILON) * 100) / 100;

        let tra_base = Math.ceil(tra.Base * 1000000) / 1000000;
        tra_base = Math.round((tra_base + Number.EPSILON) * 100) / 100;

        tra.Importe = tra_importe.toString()
        tra.Base = tra_base.toString();

        impuestos.TotalImpuestosTrasladados += parseFloat(tra.Importe);
      });

      impuestos.TotalImpuestosRetenidos = impuestos.TotalImpuestosRetenidos.toFixed(2).toString();
      impuestos.TotalImpuestosTrasladados = parseFloat((Math.round((Math.round(impuestos.TotalImpuestosTrasladados * 1000) / 1000)*100)/100)).toFixed(2).toString();

      await this.get_sucursal(this.model_ticket.sucursal._id);
      //var total_calculado = parseFloat(subtotal_calculado.toFixed(2)) + parseFloat(impuestos.TotalImpuestosTrasladados);
      //subtotal_calculado = parseFloat((Math.round((Math.round(subtotal_calculado * 1000) / 1000)*100)/100));
      subtotal_calculado = Math.ceil(subtotal_calculado * 1000000) / 1000000;
      subtotal_calculado = Math.round((subtotal_calculado + Number.EPSILON) * 100) / 100;

      var total_calculado = subtotal_calculado + parseFloat(impuestos.TotalImpuestosTrasladados);
      var total_ticket = parseFloat(this.model_ticket.total);      
      var total_factura = 0;
      //total_factura = total_calculado.toFixed(2) != total_ticket.toFixed(2) ? total_calculado : total_ticket;
      total_factura = total_calculado;
      var fecha_json = moment().format("YYYY-MM-DD HH:mm:ss").replace(' ', 'T');
      fecha_json = fecha_json.toString().substr(0,10) + "T00:00:00";

      this.json = {
        "Sello": "",
        "Serie": this.model_sucursal.clave != undefined ? this.model_sucursal.clave : "",
        "Folio": "",
        "SubTotal": subtotal_calculado.toFixed(2),
        "TipoDeComprobante": "I",
        "Total": parseFloat((Math.round((Math.round(total_factura * 1000) / 1000)*100)/100)).toFixed(2),
        "Version": "4.0",
        "Fecha": fecha_json,
        "LugarExpedicion": "98600",
        "Moneda": "MXN",
        "NoCertificado": "",
        "MetodoPago": "PUE",
        "FormaPago": "",
        "Exportacion": "01",
        "Emisor": {
          "Nombre": "LA BODEGUITA DE GUADALUPE",
          "RegimenFiscal": "601",
          "Rfc": "BGU960207S5A",
          "Domicilio": {
            "Calle": this.model_sucursal.domicilio != undefined && this.model_sucursal.domicilio != null ? this.model_sucursal.domicilio : "",
            "CodigoPostal": this.model_sucursal.codigo_postal != undefined && this.model_sucursal.codigo_postal != null ? this.model_sucursal.codigo_postal : "",
            "Colonia": this.model_sucursal.colonia != undefined && this.model_sucursal.colonia != null ? this.model_sucursal.colonia : "",
            "Estado": "Zacatecas",
            "Localidad": "",
            "Municipio": this.model_sucursal.ciudad != undefined && this.model_sucursal.ciudad != null ? this.model_sucursal.ciudad : "",
            "NumeroExterior": this.model_sucursal.numero_exterior != undefined && this.model_sucursal.numero_exterior != null ? this.model_sucursal.numero_exterior : "",
            "Pais": "MEX",
            "Referencia": ""
          }
        },
        "Receptor": {
          "Nombre": this.model_ticket.cliente != null ? this.model_ticket.cliente.nombre.toUpperCase().trim() : "",
          "Rfc": this.model_ticket.cliente != null ? this.model_ticket.cliente.rfc.toUpperCase().trim() : "",
          "UsoCFDI": "",
          "RegimenFiscalReceptor": "",
          "DomicilioFiscalReceptor": "",
          "Domicilio": {
            "Calle": "",
            "CodigoPostal": "",
            "Colonia": "",
            "Estado": "",
            "Localidad": "",
            "Municipio": "",
            "NumeroExterior": "",
            "Pais": "",
            "Referencia": ""
          }
        },
        "Conceptos": conceptos,
        "Impuestos": impuestos,
        //(23-10-2023): Alvaro solicitó que si trae ticket anterior ese sea el que se muestra en la factura
        "Observaciones": this.model_ticket.ticket_anterior ? this.model_ticket.ticket_anterior : this.model_ticket.ticket
      };

      try {
        //Si la venta se hizo al publico en general, entonces se limpian el nombre y rfc del receptor para que los ingrese el cliente
        if (this.model_ticket.cliente != null && this.model_ticket.cliente.rfc.toUpperCase() == "XAXX010101000") {
          this.json.Receptor.Nombre = "";
          this.json.Receptor.Rfc = "";
        } else {
          await this.buscar_cliente();
        }
      } catch (error) {
        this.json.Receptor.Nombre = "";
        this.json.Receptor.Rfc = "";
      }

      this.model_cfdi.rfc = this.json.Emisor.Rfc;

    },
    facturar: function() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      this.key_xml = null;
      this.key_pdf = null;      

      window.dialogLoader.show('Espere un momento por favor.. NO CIERRE LA VENTANA HASTA FINALIZAR EL PROCESO');    

      this.model_cfdi.json = this.json;
      this.model_cfdi.id_externo = this.id_venta;

      if (this.model_cliente._id != undefined && this.model_cliente._id != null) { //Se actualiza su email, cp, regimen y razon social
        this.model_cliente.nombre = this.json.Receptor.Nombre;
        this.model_cliente.datos_fiscales.razon_social = this.json.Receptor.Nombre;
        this.model_cliente.datos_fiscales.codigo_postal = this.json.Receptor.DomicilioFiscalReceptor;
        this.model_cliente.datos_fiscales.regimen_fiscal = this.json.Receptor.RegimenFiscalReceptor;

        //Se agregan los datos del domicilio
        this.model_cliente.datos_fiscales.domicilio = this.json.Receptor.Domicilio.Calle;
        this.model_cliente.datos_fiscales.numero_exterior = this.json.Receptor.Domicilio.NumeroExterior;
        this.model_cliente.datos_fiscales.colonia = this.json.Receptor.Domicilio.Colonia;
        this.model_cliente.datos_fiscales.ciudad = this.json.Receptor.Domicilio.Ciudad;
        this.model_cliente.datos_fiscales.numero_interior = this.json.Receptor.Domicilio.NumeroInterior;
        if (this.model_cliente.direcciones != undefined && this.model_cliente.direcciones != null && this.model_cliente.direcciones.length == 0) {
          this.model_cliente.direcciones[0] = {
            "domicilio": this.model_cliente.datos_fiscales.domicilio,
            "numero_exterior": this.model_cliente.datos_fiscales.numero_exterior,
            "numero_interior": this.model_cliente.datos_fiscales.numero_interior,
            "colonia": this.model_cliente.datos_fiscales.colonia,
            "ciudad": this.model_cliente.datos_fiscales.ciudad,
            "referencia": "",
            "codigo_postal": this.model_cliente.datos_fiscales.codigo_postal,
            "referencias": ""
          }
        }


        this.model_cliente.email = this.model_cfdi.email;

        window.axios.create({
            withCredentials: false
          })
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model_cliente._id + '?conflicts=true', this.model_cliente, this.db_config)
          .then(response => {
            //se actualiza cliente
            this.model_cliente._rev = response.data.rev;
          });

      } else { //Si el cliente no existe se crea en el catalogo

        let data_cliente = {
          type: "clientes",
          nombre: this.json.Receptor.Nombre,
          cargo: "",
          rfc: this.json.Receptor.Rfc,
          curp: "",
          telefono_casa: "",
          telefono_oficina: "",
          fax: "",
          tipo_persona: "",
          sexo: "",
          edad: "",
          email: this.model_cfdi.email,
          reg_iesps: "",
          fecha_alta: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
          estatus: 'Activo',
          direcciones: [],
          datos_fiscales: {
            razon_social: this.json.Receptor.Nombre,
            regimen_fiscal: this.json.Receptor.RegimenFiscalReceptor,
            domicilio: "",
            numero_exterior: "",
            numero_interior: "",
            colonia: "",
            ciudad: "",
            codigo_postal: this.json.Receptor.DomicilioFiscalReceptor,
            representante: "",
            fecha_sociedad: "",
            notario: "",
            no_escritura: "",
            ciudad_representante: ""
          }
        };

        window.axios.create({
            withCredentials: false
          })
          .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', data_cliente, this.db_config)
          .then(response => {
            //Se guarda cliente
            this.model_cliente._id = response.data.id;
            this.model_cliente._rev = response.data.rev;
            this.model_cliente.nombre = this.json.Receptor.Nombre;
            this.model_cliente.rfc = this.json.Receptor.Rfc;
          });
      }

      axios.post(this.api + 'carta_porte/guardar_mongo/', this.model_cfdi).then(async (res) => {
          if (typeof res.data !== 'undefined') {
            this.id_solicitud = res.data.id_solicitud;
            var self = this;
            var flag = {"updated": false};
            var intentos = 0;
            var error_timbrado = "";
            
            // Se hace un ciclo para estar revisando si cambia el estatus de facturacion de la venta o si tiene el campo de error
            do {
              await new Promise(r => setTimeout(r, 10000));
              await self.consultar_estatus_couch().then(r => {
                flag = r;
              }).catch(er => {
                flag = er;
              });
              intentos += 1;

            } while (flag.updated == false && intentos < 10);

            if(intentos == 10) {
              window.dialogLoader.hide();
                this.loading = false;
                this.encontrado = false;
                this.model.numero_ticket = "";
                this.id_venta = "";

                this.$swal({
                  type: "info",
                  title: "Error al Facturar",
                  text: "Se excedio el número de intentos de consulta. Por favor acuda a sucursal para solicitar su factura.",
                  footer: flag.data.error_timbrado == 'ConexionWS' ? "Intente nuevamente más tarde" : flag.data.error_timbrado,
                });

                return;
            }

            console.log("resultado flag: ",flag);
            if (flag['updated'] == true) {
              if (flag['error'] == false) {
                // Aqui se envian los emails y se muestra lo de descargar
                var key_xml = res.data.id_solicitud;
                self.key_xml = "BGU960207S5A/" + self.id_solicitud + ".xml";
                self.key_pdf = "BGU960207S5A/" + self.id_solicitud + ".pdf";

                this.enviarCorreo(key_xml);

                self.modal = true;


              } else {
                window.dialogLoader.hide();
                this.loading = false;
                this.encontrado = false;
                this.model.numero_ticket = "";
                this.id_venta = "";

                this.$swal({
                  type: "error",
                  title: "Error al Facturar",
                  text: "Verifique que sus datos coincidan tal cual los registró en el SAT e intente nuevamente. Si el error persiste acuda a sucursal para solicitar su factura.",
                  footer: flag.data.error_timbrado == 'ConexionWS' ? "Intente nuevamente más tarde" : flag.data.error_timbrado,
                });

                return;
              }
            }

            window.dialogLoader.hide();
            this.loading = false;
            this.encontrado = false;
            this.model.numero_ticket = "";
            this.id_venta = "";
          }
        })
        .catch((error) => {
          console.log(error)
          this.id_solicitud = null;
          this.encontrado = false;
          this.model.numero_ticket = "";
          this.model.digito = "";
          this.model_cfdi.email = "";
          this.key_xml = null;
          this.key_pdf = null;

          this.$swal({
            type: "error",
            title: "Error",
            text: "Ocurrió un error al guardar el registro.",
            footer: "",
          });
          window.dialogLoader.hide();
          this.loading = false;
        });
    },

    get_file: function(tipo) {

      var self = this;
      self.modal = true;

      if (tipo == 1) {
        var data_xml = {
          "key": self.key_xml
        };
        let url = this.api + 'carta_porte/get_download_url_mongo/';

        window.dialogLoader.show('Espere un momento por favor..');

        axios.post(url, data_xml)
          .then((res) => {
            window.dialogLoader.hide();
            if (typeof res.data !== 'undefined') {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = res.data;
              a.target = "_blank";
              a.click();
              a.parentNode.removeChild(a);
            }
          })
          .catch((error) => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los catalogos del SAT.",
              footer: ""
            });
            window.dialogLoader.hide();
            console.log("error al bajar el xml", error);
          });
      } else {
        var data_pdf = {
          "key": self.key_pdf
        };
        let url = this.api + 'carta_porte/get_download_url_mongo/';

        window.dialogLoader.show('Espere un momento por favor..');

        axios.post(url, data_pdf)
          .then((res) => {
            window.dialogLoader.hide();
            if (typeof res.data !== 'undefined') {
              var a = document.createElement("a");
              document.body.appendChild(a);
              a.style = "display: none";
              a.href = res.data;
              a.target = "_blank";
              a.click();
              a.parentNode.removeChild(a);
            }
          })
          .catch((error) => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al obtener los catalogos del SAT.",
              footer: ""
            });
            window.dialogLoader.hide();
            console.log("error al bajar el pdf", error);
          });
      }
    },
    enviarCorreo: function(id_solicitud) {
      var self = this;

      var data = {
        id_solicitud: id_solicitud, //requerido
        lista_emails: [this.model_cfdi.email], //requerido, debe ser un array
        responder_a: "labodeguitadeguadalupe@gmail.com", //opcional
        asunto: "La Bodeguita - Facturación", //opcional
        mensaje: "Se adjunta PDF y XML de su factura. Gracias por su compra." //opcional
      };

      let url = this.api + 'carta_porte/enviar_factura_mongo/';
      axios.post(url, data)
        .then((res) => {
          window.dialogLoader.showSnackbar('El correo se envió correctamente..', {
            color: 'success'
          });
          this.model.digito = "";
          this.model_cfdi.email = "";
        })
        .catch((error) => {
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al enviar el correo. Por favor intente nuevamente.",
            footer: ""
          });
          console.log("error al enviar correo", error);
        });

    },
    consultar_estatus_couch: function() {
      var self = this;
      return new Promise(async function(resolve, reject) {

        await window.axios.create({withCredentials: false})
          .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + self.id_venta, self.db_config)
          .then(response => {
            
            if(self.model_ticket['id_facturacion'] != undefined && self.model_ticket['id_facturacion'] != '' && self.model_ticket['id_facturacion'] != null && self.model_ticket['id_facturacion'] == response.data['id_solicitud']) {
              return resolve({"updated": false})
            }

            if(response.data['estatus'] == 'Facturada') {
              return resolve({"updated": true, "data": response.data, "error": false})
            } else if (response.data["error_timbrado"] != undefined && response.data['error_timbrado'] != null && response.data["error_timbrado"] != ""){
              return resolve({"updated": true, "data": response.data, "error": true})
            } else {
              return resolve({"updated": false})
            }
          }).catch((error) => {
            console.log("en el catch", error);
            return reject({"updated": 3, "data":{"error_timbrado": error}, "error": true})
          });
      });
    },
  },
};
</script>

<style lang="css" scoped>
#contenedor {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
}
</style>
