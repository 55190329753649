<template>
<div id="pageIndex">
    <v-container grid-list-xl fluid>
        <div>
            <v-breadcrumbs :items="breadcrumbs">
                <v-icon slot="divider">forward</v-icon>
            </v-breadcrumbs>
        </div>
    </v-container>
</div>
</template>

<script>
export default {
    components: {},
    
   
    data: () => ({
        fab: false,
        breadcrumbs: [{
                text: "Inicio",
                disabled: true,
                to: ""
            },
        ],
        colortema: false,
        mostrarmenu: false,
        expanded: true,
        rightDrawer: false,
        snackbar: {
            show: false,
            text: "",
            color: ""
        }
    }),
    computed: {},
    methods: {

    },
    mounted: function () {

    }
};
</script>

<style lang="stylus" scoped>
    .setting-fab 
        top:50% !important;
        right:0;
        border-radius:0 
    .page-wrapper 
        min-height:calc(100vh - 64px - 50px - 81px);
</style>
