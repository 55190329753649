<template>
<div id="app_mobil" style="backgroundImage: url('/static/b1.png'); background-repeat: no-repeat; background-size:cover; height:100%">
    <v-container id="contenedor" fluid tag="section" style=" top:5%; ">
        <v-row style="display:flex; justify-content:center;">
            <v-col xs="12" lg="12">

                <base-material-card color="blue" icon="mdi-barcode-scan" title="VERIFICADOR DE PRECIOS" class="elevation-1 px-5 py-3" style="color:white; background: rgb(254,245,0);
background: radial-gradient(circle, rgba(254,245,0,1) 0%, rgba(248,163,23,1) 50%, rgba(240,64,24,1) 100%); ">
                    <v-container>
                        <v-card-title>
                            <v-spacer></v-spacer>
                            <v-btn style="top:-80px;" fab small text icon @click="handleFullScreen()">
                                <v-icon>fullscreen</v-icon>
                            </v-btn>
                        </v-card-title>

                        <v-card-text>

                            <v-col sm="4" style="top:-50px;">
                                <v-text-field v-model="search" autofocus label="Código de Barras" @keyup.enter="buscar" class="input-group--focused"></v-text-field>
                            </v-col>
                            <div style="position:absolute; top: -5px; left: 50%; margin: 0 0 0 -200px;">
                                <img src="/static/logo-negro.png" alt="LaBodeguita" style="width:400px;" /></div>

                            <div class="elevation-10 px-10 py-10 ventana">
                                <div class="text-center container" style="width:100%; ">
                                    <h2 class="animate-charcter">{{model.nombre}}</h2>
                                    <h1 v-if="model.precio!=''" class="animate-charcter-precio"> $ {{parseFloat(model.precio).toFixed(2)}}</h1>

                                    <h4 style="color:#F14818">{{model.precios}}</h4>
                                </div>
                            </div>

                        </v-card-text>
                    </v-container>
                </base-material-card>
            </v-col>
        </v-row>
    </v-container>

</div>
</template>

<script>
import moment from "moment";
// import pdf from 'vue-pdf'
import Util from "@/util";

export default {

    name: "appMobil",
    components: {
        // pdf
    }

    ,

    created: function () {}

        ,

    data: () => ({

            item: {}

            ,
            search: "",
            encontrado: false,

            db_config: {
                headers: {
                    Authorization: "YWRtaW46YWRtaW4xMjMq"
                }
            }

            ,
            model: {
                id: "",
                nombre: "",
                precio: "",
                precios: "",
                codigo: "",
                descripcion: "",
                tipo_precio:"",
            },
            etiqueta: {
                type: "etiquetas",
                fecha: "",
                estatus: "", //Pendiente, Impresa, Cancelada
                articulos: [],
                usuario: "",
                observaciones: "",
            },
        }

    ),
    methods: {
        handleFullScreen() {
            Util.toggleFullScreen();
        },
        solicitudEtiqueta: function (busca) {
            var self = this;
            var cantidad_imprimir = 1;

            var _cantidad = busca.replace('ETIQUETAS', "").replace('ETIQUETA', "");
            if(_cantidad && _cantidad!="" && parseInt(_cantidad)>0 && !isNaN(parseInt(_cantidad))){
                cantidad_imprimir = parseInt(_cantidad);
                if (cantidad_imprimir > 100){ //Máximo 100 etiquetas
                    cantidad_imprimir = 100;
                }
            } 

            var etq = {
                type: "etiquetas",
                fecha: window.moment().format("YYYY-MM-DDTHH:mm:ss"),
                estatus: "Pendiente",
                articulos: [{
                    id_articulo: self.model.id,
                    nombre_articulo: self.model.nombre,
                    descripcion: self.model.descripcion,
                    codigo_articulo: self.model.codigo,
                    cantidad: cantidad_imprimir,
                    precio: self.model.precio,
                    tipo_precio: self.model.tipo_precio,
                }],
                usuario: "@Verificador",
                observaciones: "Solicitud enviada desde el Verificador de Precios",
            };          

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/', etq)
                .then(response => {
                    //console.log("Response", response);
                    window.dialogLoader.showSnackbar('Solicitud Enviada', {
                        color: 'success'
                    });
                })
                .catch(error => {
                    console.log(error);
                    window.dialogLoader.showSnackbar('Ocurrio un error al crear la solicitud. Intente nuevamente.', {
                        color: 'error'
                    });
                });

        },

        async buscar() {
            var self = this;

            var busca = encodeURI(escape(self.search.toString().trim().toUpperCase()));
            //console.log(self.model);
            //console.log(busca);
            if (busca.indexOf('ETIQUETA') != -1 && busca.startsWith('ETIQUETA')) {
                if(self.model.id != ""){
                    self.solicitudEtiqueta(busca);
                }
                this.search = "";
            } else {
                await window.axios.get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_design/articulos/_view/busca_codigo?key=\"' + busca + '\"')
                .then(response => {
                        //console.log(response.data);
                        self.model.precio = "";
                        self.model.precios = "";
                        self.model.id = "";
                        self.model.tipo_precio = "";
                        if (response.data.rows.length > 0) {
                            self.model.nombre = response.data.rows[0].value.nombre;
                            self.model.codigo = self.search;
                            self.model.descripcion = response.data.rows[0].value.descripcion;
                            self.model.id = response.data.rows[0].value._id;
                            response.data.rows[0].value.precios_venta.forEach(function (x) {
                                if (x.tipo_precio == "Público General"){
                                    self.model.precio = x.precio_con_impuestos;
                                    self.model.tipo_precio = "Público General";
                                } else {
                                    self.model.precios += "[ " + x.tipo_precio + ": de " + x.de + " a " + x.a + " = $ " + x.precio_con_impuestos + " ] ";
                                }
                            });
                            if (self.model.precio == "")
                                self.model.nombre = "El Precio no fue localizado.";
                        } else {
                            self.model.nombre = "El Producto no fue localizado.";

                        }
                        this.search = "";

                    }

                ).catch(err => {
                        console.log(err);
                    }

                );
            }
        },
    },
}

;
</script>

<style>
#contenedor {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
}

.animate-charcter {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #F14C18 67%,
            #1D89E5 100%);

    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #000;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 10s ease infinite;
    font-size: 50px;
}

.animate-charcter-precio {
    text-transform: uppercase;
    background-image: linear-gradient(-225deg,
            #231557 0%,
            #44107a 29%,
            #F14C18 67%,
            #1D89E5 100%);

    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #F14C18;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 5s ease infinite;
    font-size: 120px;
}

@keyframes textclip {

    0% {
        background-position: 0% 50%
    }

    50% {
        background-position: 100% 50%
    }

    100% {
        background-position: 0% 50%
    }

}

.ventana {
    margin-top: -20px;
    color: #000;
    width: 100%;
    height: 400px;
    border: 1px solid yellow;
    padding: 16px;
    background: rgb(254, 245, 0);
    background: radial-gradient(circle, rgba(254, 245, 0, 1) 0%, rgba(248, 163, 23, 1) 50%, rgba(240, 64, 24, 1) 100%);
}
</style>
