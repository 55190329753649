<template>
<div id="login" style="backgroundImage: url('/static/b1.png'); background-repeat: no-repeat; background-size:cover; height:100%">
    <v-container id="login" fluid tag="section" style=" top:25%; ">
        <v-layout align-center justify-center>

            <v-flex xs12 sm8 md4 lg4>
                <v-card flex-end class="elevation-5 input-wrapper text-center" style=" top:-30px; height:30px; width:100%; background-color: #E33E32;
                            position: relative;
                              float: right;
                                      opacity: 0.7;
                                      filter: alpha(opacity=60); ">
                    <span class="font-weight-bold white--text overline" >Acceso al Sistema</span>
                </v-card>
                <v-card class="elevation-5 pa-5 pt-0" style="background: rgba(265, 265, 265, 0.6);">

                     <div class="text-center" style="width:100%; " >
                            <img src="/static/logo-negro.png" alt="LaBodeguita" style="margin-top: -20px; width:400px; opacity: 0.9; filter: alpha(opacity=90);" />

                        </div>

                    <v-card-text>


                        <v-form>

                            <v-text-field append-icon="person" name="login" label="Ingrese su nombre de Usuario" type="text" hide-details backgoround-color="transparent" v-model="model.email" @input="signalChange" @keyup.enter="(model.email !== '')? validaMail() : ''" :rules="[rules.required]" color="primary" autocomplete="new-username" autofocus @focus="$event.target.select()" ref="login" class="input-group--focused"></v-text-field>
                            <input name="login" style="display:none;" autocomplete="username" />
                            <v-text-field :append-icon="iconpass" @click:append="mostrarPass" name="password" @keyup.enter="(model.password !== '')? login() : ''" label="Ingrese su contraseña" :type="passwordFieldType" v-model="model.password" :rules="[rules.required]" color="success" ref="password" autocomplete="new-password" class="input-group--focused secure"></v-text-field>
                            <input name="password" style="display:none;" autocomplete="username" />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-layout row>
                            <v-spacer />
                            <v-btn small color="primary" dark v-bind:disabled="model.password === ''" :loading="loading" @click="login">
                                <v-icon small>input</v-icon> &nbsp;Ingresar
                                <v-icon color="warning">chevron_right</v-icon>
                            </v-btn>

                        </v-layout>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>

    </v-container>
</div>
</template>

<script>


export default {

    data: () => ({
        fab: false,
        title: "",
        permisos: [],
        respuestalogin: false,
        loading: false,

        passwordFieldType: "password",
        iconpass: "fa-eye",

        rules: {
            required: value => !!value || "El campo no puede ir vacio",
            email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return pattern.test(value) || "Correo electrónico no valido";
            }
        },
        model: {
            email: "",
            password: ""
        }
    }),

    created: function () {

           /*  const requestOptions = {
                    method: "POST",
                    body: JSON.stringify({  user: 'pumas',
                    pass: 'pumas'})
                };
                fetch("http://138.68.237.49/api-gas/login", requestOptions)
                    .then(response => response.json())
                    .then(data => (console.log(data)));


           const requestOptions2 = {
                    method: "POST",
                     body: JSON.stringify({  id: 'Z-003'})
                };
                fetch("http://138.68.237.49/api-gas/cliente_equipo", requestOptions2)
                    .then(response => response.json())
                    .then(data => (console.log(data)));
 */


        },


    mounted: function () {
          //   window.dialogLoader.showSnackbar('mensaje', { color: 'error' } );
          //  this.$swal('Hello Vue world!!!');
    },

    methods: {
        mostrarPass: function (evt) {
            this.passwordFieldType =
                this.passwordFieldType === "password" ? "text" : "password";
            this.iconpass =
                this.passwordFieldType === "password" ? "fa-eye" : "fa-eye-slash";
        },

        signalChange: function (evt) {
            this.$emit("change", evt);
            this.isEmailValido = false;
        },

        mostrarmenu: function (val) {
            //Se actualiza la variable store.state.datetime para forzar a recargar el componente de AppBar.vue
            //Esta variable se manda llamar en el archivo App.vue
            this.$store.state.datetime = this.$moment(new Date()).format("x"); //Unix timestamp
        },
        login() {
            var url = process.env.VUE_APP_COUCHDB_URL + "/_session";
            var datos = {
                    name: this.model.email,
                    password: this.model.password
            };

            var self = this;
            window.dialogLoader.show('Espere un momento por favor..');
            window.axios.create({withCredentials: true })
                .post(url, datos)
                .then(function (response) {
                    let respuesta = response.data;
                    self.$local_storage.set("sb_role", "");
                    self.$local_storage.set("sb_usuario", "");
                    self.$local_storage.set("sb_email_usuario", "");

                    if(respuesta.name != ""){
                         if(respuesta.roles[0] != '_admin'){

                                window.axios.create({
                                        withCredentials: true
                                    })
                                    .get(process.env.VUE_APP_COUCHDB_URL + "/_users/org.couchdb.user:" + respuesta.name )
                                    .then(response => {
                                        if(response.data.estatus== 'Activo' ){

                                            let data = {
                                                "selector": {"type": "configuracion"},
                                                "fields": ["perfiles"]
                                            };

                                            window.axios.create({withCredentials: true})
                                            .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
                                            .then(responsePerfil => {
                                                if (responsePerfil.data.docs.length > 0 && responsePerfil.data.docs[0].perfiles!=null) {
                                                    var perfiles = responsePerfil.data.docs[0].perfiles;
                                                    for(var k in perfiles){
                                                        if(perfiles[k].uuid == response.data.perfil.uuid){
                                                            perfiles[k].permisos.forEach(function(e){
                                                                self.$local_storage.set(e.clave, 100 );
                                                            });
                                                        }
                                                    }
                                                }
                                            })
                                            .catch(error => {
                                                window.dialogLoader.showSnackbar('Error al obtener permisos, comuníquese con el administrador.', { color: 'error' } );
                                            }).then(async ()=>{
                                                let up = response.data;
                                                up.ultimo_movimiento = Date.now();
                                                await window.axios.create({
                                                  withCredentials: true
                                                })
                                                .put(process.env.VUE_APP_COUCHDB_URL + '/_users/' + up._id + '?conflicts=true', up)
                                                .then(response_up => {
                                                  console.log("updated last move");
                                                })
                                                .catch(error_up => {
                                                  console.log("error last move");
                                                });

                                                self.$local_storage.set("sb_perfil", response.data.perfil.nombre );
                                                self.$local_storage.set("sb_usuario", respuesta.name );
                                                self.$local_storage.set("sb_email_usuario", response.data.email );
                                                self.$local_storage.set("sb_nombre_usuario", response.data.nombre );
                                                self.$local_storage.set("sb_sucursal", response.data.sucursal._id );
                                                self.$local_storage.set("sb_sucursal_usuario", response.data.sucursal._id );
                                                self.$local_storage.set("sb_nombre_sucursal", response.data.sucursal.nombre );
                                                self.$store.state.sucursal =  response.data.sucursal._id;

                                                if(response.data.sucursal._id != 0){
                                                    self.$user.set({role: "sucursal" });
                                                    self.$local_storage.set("sb_role", "sucursal");
                                                }
                                                else{
                                                    self.$user.set({role: "admin" });
                                                    self.$local_storage.set("sb_role", "admin");

                                                }
                                            });


                                        }else{
                                            window.dialogLoader.showSnackbar('El usuario esta inactivo comuníquese con el administrador.', { color: 'error' } );
                                        }

                                    })
                                    .catch(error => {
                                        console.log(error);
                                        window.dialogLoader.showSnackbar('Ocurrió un error al obtener el usuario.', { color: 'error' } );
                                    });
                        }else{
                            self.$local_storage.set("sb_role", respuesta.roles[0] );
                            self.$local_storage.set("sb_usuario", respuesta.name );                            
                            self.$local_storage.set("sb_nombre_usuario", respuesta.name );
                            self.$user.set({ role: respuesta.roles[0] });
                        }

                        window.dialogLoader.showSnackbar('Se inició sesión correctamente..', { color: 'success' } );
                    }else{
                        window.dialogLoader.showSnackbar('Usuario o contraseña no valida', { color: 'error' } );
                    }

                })
                .catch(function (error) {
                    console.log(error);
                    window.dialogLoader.showSnackbar('ACCESO DENEGADO!!!!', { color: 'error' } );
                    window.dialogLoader.hide();
                })
                .then(() => {
                    window.dialogLoader.hide();
                });

        },


    }
};
</script>

<style lang="css" scoped>
#login {
    height: 50%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    z-index: 0;
}
</style>
